import "./tariffs.scss";
import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import CardCost from "@components/card/card-cost";
import TariffsSection from "@components/card/tariffs";
import Select from "@components/select";

const Tariffs = () => {
	const cards = useStaticQuery(graphql`
		query {
			allMarkdownRemark(
				filter: { frontmatter: { path: { regex: "/cards/" } } }
			) {
				edges {
					node {
						frontmatter {
							path
							title
							cost
							description
							thumbnail
							tariffs {
								type
								name
								onlyCost
								rideCost
								rideCount
								totalCost
								tariffPeriod
								refillPeriod
								moreInfo
							}
						}
					}
				}
			}
		}
	`).allMarkdownRemark.edges.map(item => item.node.frontmatter);

	const selectedCards = cards.filter(
		card => card.tariffs && card.tariffs.length
	);

	const options = selectedCards.map((item, index) => ({
		value: index,
		label: item.title
	}));

	const [selectedCard, setSelectedCard] = useState(options[3]);
	const { cost, tariffs, path } = selectedCards[selectedCard.value];

	const onChange = value => {
		setSelectedCard(value);
	};

	return (
		<section className="tariffs-separately">
			<div className="grid-container grid-container--eight">
				<div className="row">
					<div className="col-xs-12 col-sm-6 col-md-4 col-md-offset-2">
						<Select
							value={selectedCard}
							onChange={onChange}
							options={options}
							placeholder="Выберите карту"
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-xs-12 col-sm-6 col-md-4 col-md-offset-2">
						<CardCost
							className="tariffs-separately__card-cost"
							value={cost}
							path={path}
						/>
					</div>
				</div>
			</div>
			<TariffsSection isHeaderShown={false} data={tariffs} path={path} />
		</section>
	);
};

export default Tariffs;
